@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');



@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
	font-family: 'neuropol';
	src: url(../fonts/neuropol.otf);
}

body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
		'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
}

@layer utilities {
	.clip-top-left-corner-border {
		content: '';
		position: absolute;
		inset: 0;
		z-index: -1;
		clip-path: polygon(0 20px,
				20px 0,
				100% 0,
				100% 100%,
				0 100%,
				0 20px,
				2px calc(20px + 0.83px),
				2px calc(100% - 2px),
				calc(100% - 2px) calc(100% - 2px),
				calc(100% - 2px) 2px,
				calc(20px + 0.83px) 2px,
				2px calc(20px + 0.83px));
	}

	.clip-top-left-corner-border {
		content: '';
		position: absolute;
		inset: 0;
		z-index: -1;
		clip-path: polygon(0 20px,
				20px 0,
				100% 0,
				100% 100%,
				0 100%,
				0 20px,
				2px calc(20px + 0.83px),
				2px calc(100% - 2px),
				calc(100% - 2px) calc(100% - 2px),
				calc(100% - 2px) 2px,
				calc(20px + 0.83px) 2px,
				2px calc(20px + 0.83px));
	}

	.clip-top-right-corner-border {
		content: '';
		position: absolute;
		inset: 0;
		z-index: -1;
		clip-path: polygon(0 0,
				calc(100% - 20px) 0,
				100% 20px,
				100% 100%,
				0 100%,
				0 0,
				2px 2px,
				2px calc(100% - 2px),
				calc(100% - 2px) calc(100% - 2px),
				calc(100% - 2px) calc(20px + 0.83px),
				calc(100% - 20px - 0.83px) 2px,
				2px 2px);
	}

	.clip-bottom-left-corner-border {
		content: '';
		position: absolute;
		inset: 0;
		z-index: -1;
		clip-path: polygon(0 0,
				100% 0,
				100% 100%,
				20px 100%,
				0 calc(100% - 20px),
				0 0,
				2px 2px,
				2px calc(100% - 20px - 0.83px),
				calc(20px + 0.83px) calc(100% - 2px),
				calc(100% - 2px) calc(100% - 2px),
				calc(100% - 2px) 2px,
				2px 2px);
	}

	.clip-top-left-corner-solid {
		clip-path: polygon(20px 0, 100% 0, 100% 100%, 0 100%, 0 20px);
	}

	.clip-top-right-corner-solid {
		clip-path: polygon(20px 0, 100% 0, 100% 100%, 0 100%, 0 20px);
	}

	.clip-bottom-right-corner-solid {
		clip-path: polygon(100% 0, 100% 20px, 20px 100%, 0 100%, 0 0);
	}

	.clip-bottom-left-corner-solid {
		clip-path: polygon(0 0, 100% 0, 100% 100%, 20px 100%, 0 20px);
	}
}

.gradient {
	background: radial-gradient(#FF00FF20, #000000);
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	/* display: none; <- Crashes Chrome on hover */
	-webkit-appearance: none;
	margin: 0;
	/* <-- Apparently some margin are still there even though it's hidden */
}

input[type=number] {
	-moz-appearance: textfield;
	/* Firefox */
}

@keyframes pulsateBorder {
	0% {
		border-color: red;
	}

	100% {
		border-color: white;
	}
}

.pulsateBorder {
	animation: pulsateBorder 1s infinite alternate ease-in;
}

.swiper-button-prev, .swiper-button-next{
	color: #00ccff !important;
}

.veriff-description {
	color: white !important;
}